"use strict";

// go top
$(document).ready(function(){
  $(window).scroll(function(){
    if ($(this).scrollTop() > 100) {
    $('.scrollToTop').fadeIn();
    } else {
    $('.scrollToTop').fadeOut();
    }
  });

  $('.scrollToTop').click(function(){
    $('html, body').animate({scrollTop : 0},400);
    return false;
  });
});

// dropdown
function myFunction() {
document.getElementById("navDropdown").classList.toggle("show");
}
window.onclick = function (e) {
if (!e.target.matches('.dropbtn')) {
        var myDropdown = document.getElementById("navDropdown");
        if (myDropdown.classList.contains('show')) {
        myDropdown.classList.remove('show');
        }
}
};

// dot
var slideIndex2 = 1;
showSlides2(slideIndex2);

function currentSlide2(n) {
        showSlides2(slideIndex2 = n);
}

function showSlides2(n) {
        var i;
        var slides = document.getElementsByClassName("sidecompany");
        var dots = document.getElementsByClassName("companyedot");
        if (n > slides.length) {
                slideIndex2 = 1;
        }
        if (n < 1) {
                slideIndex2 = slides.length;
        }
        for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace("companyactive", "");
        }
        slides[slideIndex2 - 1].style.display = "block";
        dots[slideIndex2 - 1].className += " companyactive";
}

